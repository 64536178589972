import React, { useEffect, useState, useRef } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import {
  CircularProgress,
  Divider,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TableHead,
  TableContainer,
  Paper,
  TableRow,
  TableBody,
  Table,
  Box,
} from '@mui/material';
import { DocumentVaultItemModel } from '../../../../models/document.vault.item.model';
import { TabProps } from './types';
import { NWClient } from '../../../../client/NWClient';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { selectUser } from '../../../../slices/user/userSlice';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import DescriptionIcon from '@mui/icons-material/Description';
import { StyledTableCell } from '../../../category/components/TemplatesTable/components/TableCell';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import { uploadDocument } from '../../../../slices/documents/documentsSlice';

export const DocumentsTab = ({ currentTab }: TabProps) => {
  const token = localStorage.getItem('access_token');
  const currentUser = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [documents, setDocuments] = useState<any[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);
  const [employeeId, setEmployeeId] = useState<number>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const formRef = useRef(null);
  const category = 'employment';

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await NWClient.list(token, 'document-upload', undefined, 'employees');
        console.log(response);
        const filteredDocuments = response.filter((doc: any) => {
          return doc.employees.includes(employeeId);
        });
        console.log(filteredDocuments);
        setDocuments(filteredDocuments);
      } catch (error) {
        console.error('Error fetching documents:', error);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      } finally {
        setLoading(false);
      }
    };
    fetchDocuments();
  }, [employeeId, token, t]);

  const handleDownloadLink = (id: number, name: string) => {
    setLoading(true);
    NWClient.downloadDocument(token, Number(id), name)
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => {
        setLoading(false);
        toast.success(t('messages.downloadSuccess'), { theme: 'colored' });
      });
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setPendingStatus(true);
      try {
        const data = new FormData();
        Array.from(files).forEach((file) => {
          data.append('document_file', file);
        });
        data.append('employeeId', employeeId.toString());
        data.append('category', category);
        data.append('user', String(currentUser.id));

        await dispatch(uploadDocument({ token, data }))
          .unwrap()
          .then((res: any) => {
            NWClient.patch(token, `document-upload/${res.id}/`, null, { employees: [employeeId] });
          });
        toast.success(t('messages.uploadSuccess'), { theme: 'colored' });
        setUploadedFiles([]);
      } catch (error) {
        console.error('Error uploading document:', error);
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      } finally {
        setPendingStatus(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser.id)
        try {
          const res: any[] = await NWClient.getByUser(token, 'employee', currentUser.id);
          setEmployeeId(res[0].id);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
    };
    fetchData();
  }, [currentUser.id, dispatch, token]);

  return (
    <TabPanel value={currentTab} index={4} prefix={'vertical'}>
      <Grid container item className='setting-panel-header' alignItems='center'>
        <DescriptionIcon />
        <h3>{t('settings.titles.documents')}</h3>
      </Grid>
      <TableContainer
        elevation={0}
        sx={{ py: 2, px: 3, borderRadius: 5 }}
        component={Paper}
        style={{ marginBottom: '1.5rem' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>{t('labels.name')}</StyledTableCell>
              <StyledTableCell align='right'>
                {t('templatesTable.actionColumnLabel')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document) => (
              <TableRow key={document.id}>
                <StyledTableCell>{document.name}</StyledTableCell>
                <StyledTableCell align='right'>
                  <Tooltip title={t('buttons.download')} placement='top' arrow>
                    <IconButton
                      className='table-row-icon-button'
                      onClick={() => {
                        handleDownloadLink(document.id, document.name);
                      }}
                      aria-label={`download ${document.name} button`}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <input
          type='file'
          name='document_file'
          multiple
          style={{ display: 'none' }}
          ref={formRef}
          onChange={handleUpload}
        />
        <Button
          variant='contained'
          sx={{ marginBottom: '1rem', marginRight: '1.5rem' }}
          onClick={() => formRef.current.click()}
        >
          {t('buttons.upload')}
        </Button>
      </Box>
    </TabPanel>
  );
};
