import React from 'react';
import Brand from '../../../static/assets/svg/brand.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../constants/routes';

interface ProcessMessageProps {
  text: string;
  messageType: 'emailSent' | 'success';
}

const ProcessMessage = (props: ProcessMessageProps) => {
  const [searchParams] = useSearchParams();
  const accountConfirmed = searchParams.get('account_confirmed');
  const passwordResetConfirmed = searchParams.get('password_reset_confirmed');
  const icon = () => {
    switch (props.messageType) {
      case 'emailSent':
        return <Brand />;
      case 'success':
        return <CheckCircleOutlineIcon />;
      default:
        return <Brand />;
    }
  };
  return (
    <main className={'process-message-container ' + props.messageType}>
      <div>
        <div className='image-container'>{icon()}</div>
        <div className='message-text'>
          {props.text}
          {accountConfirmed || passwordResetConfirmed ? (
            <div>
              <Link to={ROUTES.LOGIN}>You can login now.</Link>
            </div>
          ) : null}
        </div>
      </div>
    </main>
  );
};

export default ProcessMessage;
