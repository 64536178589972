import { FORM_ITEMS } from '../constants/form_items';
import { Dispatch, SetStateAction } from 'react';

interface Obj {
  [key: string]: string | object;
}

interface ResponseDataObject extends Obj {
  message: string;
}

interface ResponseObject extends Obj {
  data: ResponseDataObject;
}

export interface ErrorObject extends Obj {
  response: ResponseObject;
  request: Obj;
}

export const Helpers = {
  handleRequestError: (
    errorData: ResponseObject,
    setErrorTextArray: (
      errorTextArray: { [key: string]: string | object }[]
    ) => void | Dispatch<SetStateAction<{ [key: string]: string | object }[]>>,
    setPendingStatus: (flag: boolean) => void
  ) => {
    const errorTextArray = [];
    const obj = errorData.data;
    if (typeof obj === 'object') {
      Object.keys(obj).forEach((el) => {
        const errorItem = {
          [el]: obj[el],
        };
        errorTextArray.push(errorItem);
      });
    } else if (errorData.statusText) {
      errorTextArray.push({ statusText: errorData.statusText });
    } else {
      errorTextArray.push({ errorText: FORM_ITEMS.BASIC_ERROR_TEXT });
    }
    setErrorTextArray(errorTextArray);
    setPendingStatus(false);
  },
  formatDBDateToUI: (date: string) =>
    date ? date.replace(/(\d{4})-(\d{2})-(\d{2}).*/, '$3.$2.$1') : null,
  returnErrorText: (error: ErrorObject) => {
    const errorMessage =
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : error.message
        ? error.message
        : error.request.statusText;
    return errorMessage;
  },
  a11yProps(index: number, prefix: string) {
    return {
      id: `${prefix}-tab-${index}`,
      'aria-controls': `${prefix}-tabpanel-${index}`,
    };
  },
  timeStampToDate(timeStamp: string): string {
    return (
      timeStamp.substring(8, 10) + '.' + timeStamp.substring(5, 7) + '.' + timeStamp.substring(0, 4)
    );
  },
  timeStampToDateV2(timeStamp: string): string {
    const options = { year: '2-digit', month: 'short', day: 'numeric' };

    return (
      // @ts-expect-error: should be fine with options
      new Date(timeStamp).toLocaleDateString('en-AU', options)
    );
  },
};
