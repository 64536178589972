import React from 'react';
import DialogItem from '../dialog-item/DialogItem';
import Button from '@mui/material/Button';
import { ROUTES } from '../constants/routes';
import { PlanUpgradingMessageProps } from './types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PlanUpgradingMessage = ({ open, text, handleClose }: PlanUpgradingMessageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <DialogItem
      isErrorMessage={false}
      open={open}
      title={null}
      text={text}
      noDefaultActionsRow={true}
      handleClose={handleClose}
    >
      <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
        <Button
          onClick={handleClose}
          style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
          type='button'
          role='button'
          variant='outlined'
          size='medium'
        >
          {t('buttons.later')}
        </Button>
        <Button
          type='button'
          role='button'
          variant='contained'
          size='medium'
          style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
          onClick={() => {
            navigate(ROUTES.PRICING);
          }}
        >
          {t('buttons.ok')}
        </Button>
      </div>
    </DialogItem>
  );
};

export default PlanUpgradingMessage;
