import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
//import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
//import rootReducer from './reducers';
import theme from './app/common/theme/theme';

import { BrowserRouter } from 'react-router-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './scss/styles.scss';
import CssBaseline from '@mui/material/CssBaseline';

import 'bootstrap/dist/css/bootstrap.min.css';
import store from '../src/app/store';
import { ThemeProvider } from '@mui/material/styles';

import './i18n/config';

// const store = createStore(rootReducer);

import App from './app';

const root = createRoot(document.getElementById('entry'));

root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
      </BrowserRouter>
    </Provider>
  </StrictMode>
);
