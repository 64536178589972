import React from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { TabProps } from './types';
import SupplierDocumentsList from '../../SupplierDocumentsList';

export const SupplierDocumentsTab = ({ currentTab }: TabProps) => {
  return (
    <TabPanel value={currentTab} index={2} prefix={'vertical'}>
      <>
        <SupplierDocumentsList />
      </>
    </TabPanel>
  );
};
