import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import BrandName from '../../../static/assets/svg/brand-name.svg';
import Brand from '../../../static/assets/svg/brand.svg';

interface StartPageTemplateProps {
  brandContainerContent: JSX.Element;
  secondaryContainerContent: JSX.Element;
  linkToPage?: JSX.Element;
}

const StartPageTemplate = (props: StartPageTemplateProps) => {
  const min992 = useMediaQuery('(min-width:992px)');
  const max991 = useMediaQuery('(max-width: 991px)');
  const boxStyles = () => {
    if (min992) {
      return { minHeight: '100vh', height: '100%', paddingTop: '6rem' };
    }
  };

  const brandPartStyles = () => {
    if (max991) {
      return {
        order: 2,
      };
    }
  };

  const secondaryPartStyles = () => {
    if (max991) {
      return {
        order: 1,
      };
    }
  };

  const basicContainerStyles = { margin: '0 auto' };

  return (
    <div className='start-page'>
      <header>
        <Grid container style={{ ...basicContainerStyles }}>
          <Grid item xs={12}>
            <div className='logo-container' role='img' aria-label='Logo'>
              <Brand />
              <BrandName />
            </div>
            {props.linkToPage}
          </Grid>
        </Grid>
      </header>
      <Grid container style={{ ...basicContainerStyles, position: 'relative' }}>
        <Grid item xs={12} md={6} style={{ ...brandPartStyles() }}>
          <Box
            sx={{
              ...boxStyles(),
            }}
          >
            <div style={{ position: 'relative', zIndex: 3 }}>{props.brandContainerContent}</div>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{ ...secondaryPartStyles() }}>
          <Box sx={{ ...boxStyles() }}>{props.secondaryContainerContent}</Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default StartPageTemplate;
