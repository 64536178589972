import React from 'react';
import StartPageTemplate from '../../common/start-page-template/StartPageTemplate';
import PassConfirmationForm from './PassConfirmationForm';
import Button from '@mui/material/Button';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ROUTES } from '../../common/constants/routes';
import { useTranslation } from 'react-i18next';
import FeaturesContentLogIn from '../login/FeaturesContentLogIn';

const PassConfirmation = () => {
  const location = useLocation();
  const invitationURL = location.pathname.includes('join_company');
  const { t } = useTranslation();
  const FormContent = () => {
    return (
      <div className='user-form-container'>
        <h1>{invitationURL ? t('signUpText.headerInvitation') : t('signUpText.header')}</h1>
        {invitationURL ? null : <p className='text-center'>{t('signUpText.subheader')}</p>}
        <PassConfirmationForm />
      </div>
    );
  };
  const BrandContent = () => {
    return (
      <div className='features-container'>
        <FeaturesContentLogIn />
      </div>
    );
  };
  const LinkToLogin = () => {
    return (
      <Button component={RouterLink} variant='contained' size='medium' to={ROUTES.LOGIN}>
        {t('buttons.logIn')}
      </Button>
    );
  };
  return (
    <StartPageTemplate
      brandContainerContent={<BrandContent />}
      secondaryContainerContent={<FormContent />}
      linkToPage={<LinkToLogin />}
    />
  );
};

export default PassConfirmation;
