import React, { ChangeEvent, useEffect, useState } from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { CircularProgress, Divider, Grid } from '@mui/material';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { TabProps } from './types';
import { NWClient } from '../../../../client/NWClient';
import { toast } from 'react-toastify';
import CompanySettingsIcon from '../../../../../static/assets/svg/menu/business-icon.svg';
import { useTranslation } from 'react-i18next';
import { ProgressOverlay } from '../../../../common/progress-overlay/ProgressOverlay';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../../../common/hooks/useAppDispatch';
import { fetchCompanies, selectAllCompanies } from '../../../../slices/companies/companiesSlice';
import { CompanyMember } from '../../../../models/member.model';
import { InviteEmployeePanel } from '../../../templates/components/InviteEmployeePanel';
import CompanyContactItem from '../CompanyContactItem/CompanyContactItem';

export const CompanyContactsTab = ({ currentTab }: TabProps) => {
  const token = localStorage.getItem('access_token');
  const company = useAppSelector(selectAllCompanies)[0];
  const dispatch = useAppDispatch();
  const employees: CompanyMember[] = company?.members || [];
  const employeesFiltered = employees.filter((el) =>
    el.groups.every((item) => item.name !== 'Company admin')
  );
  const employeesFilteredVsContacts = employeesFiltered.filter((el) =>
    company.contacts.every(
      (item) =>
        (el.email !== item.email_address && !el.details) ||
        (el.details && el.details.email_address && el.details.email_address !== item.email_address)
    )
  );
  const employeesSorted = employeesFilteredVsContacts.sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  );
  const [pendingStatus, setPendingStatus] = useState(false);
  const [companyFetched, setCompanyFetched] = useState(false);
  const [employeeSelected, setEmployeeSelected] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setPendingStatus(true);
    const employeeChosen = employeesSorted.find((el) => el.id === parseInt(employeeSelected));
    if (employeeChosen) {
      const data = {
        first_name: employeeChosen.first_name,
        middle_name: employeeChosen.middle_name,
        last_name: employeeChosen.last_name,
        email_address:
          (employeeChosen.details && employeeChosen.details.email_address) || employeeChosen.email,
        phone_number: (employeeChosen.details && employeeChosen.details.contact_number) || null,
        job_title: (employeeChosen.details && employeeChosen.details.job_title) || null,
        authorized_rep: true,
        company_id: company.id,
      };
      NWClient.post(token, 'company_contacts', data, true)
        .then(() => {
          setEmployeeSelected('');
          setPendingStatus(false);
          dispatch(fetchCompanies(token))
            .unwrap()
            .then(() => {
              toast.success(t('messages.contactCreated'), { theme: 'colored' });
            })
            .catch(() => {
              toast.error(t('messages.errorOccurred'), { theme: 'colored' });
            })
            .finally(() => {
              setPendingStatus(false);
            });
        })
        .catch(() => {
          setPendingStatus(false);
          toast.error(t('messages.errorOccurred'), { theme: 'colored' });
        });
    }
  };

  useEffect(() => {
    setLoading(true);
    if (company) {
      setLoading(false);
    } else if (!company && !companyFetched) {
      setCompanyFetched(true);
      dispatch(fetchCompanies(token))
        .unwrap()
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token, company, companyFetched]);

  return (
    <>
      <TabPanel value={currentTab} index={2} prefix={'vertical'}>
        {loading ? (
          <Grid container justifyContent='center'>
            <ProgressOverlay className='position-relative' />
            {/*<CircularProgress/>*/}
          </Grid>
        ) : (
          <Grid>
            <Grid container item className='setting-panel-header' alignItems='center'>
              <CompanySettingsIcon />
              <h3>{t('settings.titles.companyContacts')}</h3>
            </Grid>
            <Divider />
            <div className='setting-panel-body'>
              <p className='mb-3 fs-5'>{t('messages.addEmployeeAsContact')}</p>

              <ValidatorForm onSubmit={handleSubmit} className='mb-4'>
                <Grid container className='form-controls mb-0' spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      select
                      label={t('labels.chooseEmployee')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setEmployeeSelected(e.target.value);
                      }}
                      name='employee'
                      disabled={employeesFilteredVsContacts.length === 0}
                      value={
                        employeesFilteredVsContacts.length === 0 ? 'default' : employeeSelected
                      }
                    >
                      {employeesFilteredVsContacts.length === 0 && (
                        <MenuItem selected={true} value='default'>
                          {t('messages.addEmployeeToAuthorize')}
                        </MenuItem>
                      )}
                      {employeesSorted.map((option) => (
                        <MenuItem key={option.id} value={String(option.id)}>
                          {option.first_name + ' ' + option.last_name}
                        </MenuItem>
                      ))}
                    </TextValidator>
                  </Grid>
                  <Grid className='d-flex align-items-end' item xs={12} md={6}>
                    {employeeSelected && (
                      <Button
                        style={{ marginRight: '0.5rem' }}
                        type='submit'
                        role='button'
                        disabled={!employeeSelected}
                        variant='contained'
                        size='medium'
                      >
                        {t('buttons.add')}
                      </Button>
                    )}
                    <div className='ms-auto flex-shrink-0'>
                      <InviteEmployeePanel employees={company?.members} renderOnlyIcon={true} />
                    </div>
                  </Grid>
                </Grid>
              </ValidatorForm>
              {company &&
                company.contacts &&
                company.contacts.map((el) => (
                  <CompanyContactItem
                    key={el.id}
                    authorizedRepValue={el.authorized_rep}
                    contactId={el.id}
                    contactFirstName={el.first_name}
                    contactLastName={el.last_name}
                    companyId={company.id}
                    employeeId={
                      company.members.find(
                        (item) =>
                          item.email === el.email_address ||
                          (item.details && item.details.email_address) === el.email_address
                      )?.id
                    }
                  />
                ))}
            </div>
          </Grid>
        )}
      </TabPanel>
      {pendingStatus ? <ProgressOverlay /> : null}
    </>
  );
};
