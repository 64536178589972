import React from 'react';
import { Grid } from '@mui/material';
import { SettingsMenu } from './components/SettingsMenu';
import { SupplierDetailsTab } from './components/tabs/SupplierDetailsTab';
import { SupplierContactTab } from './components/tabs/SupplierContactTab';
import { SupplierDocumentsTab } from './components/tabs/SupplierDocumentsTab';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const SupplierProfilePage = () => {
  const { supplierId } = useParams();
  const { t } = useTranslation();
  const company = useAppSelector(selectAllCompanies)[0];
  const supplier = company?.suppliers.find((el) => el.id === Number(supplierId));
  const [currentTab, setCurrentTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      {company ? (
        <h1 className='mb-4'>{`${
          supplier ? supplier.name : t('supplierPage.createSupplierHeader')
        }`}</h1>
      ) : null}
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <SettingsMenu value={currentTab} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} md={9}>
          <div className='setting-panel mb-5'>
            <SupplierDetailsTab currentTab={currentTab} />
            <SupplierContactTab currentTab={currentTab} />
            <SupplierDocumentsTab currentTab={currentTab} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default SupplierProfilePage;
