export const TAGS_LABELS = {
  documentType: [
    'tagsLabels.documentType.agreementOrContract',
    'tagsLabels.documentType.invoice',
    'tagsLabels.documentType.certificate',
    'tagsLabels.documentType.insurance',
    'tagsLabels.documentType.form',
    'tagsLabels.documentType.policiesAndProcedures',
    'tagsLabels.documentType.letter',
  ],
  documentWorkflow: {
    employment: [
      'tagsLabels.documentWorkflow.employment.recruitment',
      'tagsLabels.documentWorkflow.employment.onboarding',
      'tagsLabels.documentWorkflow.employment.employeeManagement',
      'tagsLabels.documentWorkflow.employment.termination',
    ],
    supplier: [
      'tagsLabels.documentWorkflow.supplier.proposalOrQuote',
      'tagsLabels.documentWorkflow.supplier.agreement',
      'tagsLabels.documentWorkflow.supplier.projectMaterials',
      'tagsLabels.documentWorkflow.supplier.invoicing',
      'tagsLabels.documentWorkflow.supplier.termination',
    ],
    customer: [
      'tagsLabels.documentWorkflow.customer.proposalOrQuote',
      'tagsLabels.documentWorkflow.customer.agreement',
      'tagsLabels.documentWorkflow.customer.invoicing',
      'tagsLabels.documentWorkflow.customer.tenders',
      'tagsLabels.documentWorkflow.customer.termination',
      'tagsLabels.documentWorkflow.customer.customerProjectMaterials',
    ],
    company: [
      'tagsLabels.documentWorkflow.company.registration',
      'tagsLabels.documentWorkflow.company.ownership',
      'tagsLabels.documentWorkflow.company.accounting',
      'tagsLabels.documentWorkflow.company.agreements',
      'tagsLabels.documentWorkflow.company.operations',
      'tagsLabels.documentWorkflow.company.subscriptions',
    ],
  },
  categories: [
    'tagsLabels.categories.employment',
    'tagsLabels.categories.business',
    'tagsLabels.categories.customers',
    'tagsLabels.categories.suppliers',
  ],
};
