export const STRIPE_LINKS = {
  AU: 'https://pay.businesskitz.com.au',
};

export const PRODUCT_PLANS_LINKS = {
  TEST: {
    BUSINESS_STARTER: `${STRIPE_LINKS.AU}/b/test_6oEg0p1bkd816TCaEG`,
    BUSINESS_ESSENTIALS: `${STRIPE_LINKS.AU}/b/test_dR68xXf2a3xrgucaEH`,
    BUSINESS_GROWTH: `${STRIPE_LINKS.AU}/b/test_dR64hH1bk9VP91KbIM`,
  },
  PROD: {
    BUSINESS_STARTER: `${STRIPE_LINKS.AU}/b/cN2dRqcjvfPRg2k6os`,
    BUSINESS_ESSENTIALS: `${STRIPE_LINKS.AU}/b/dR64gQfvH8np17q7sv`,
    BUSINESS_GROWTH: `${STRIPE_LINKS.AU}/b/5kA8x6gzL9rt3fy5kp`,
  },
};
