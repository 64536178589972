import React from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { TabProps } from './types';
import CustomerDocumentsList from '../../CustomerDocumentsList';

export const CustomerDocumentsTab = ({ currentTab }: TabProps) => {
  return (
    <TabPanel value={currentTab} index={3} prefix={'vertical'}>
      <>
        <CustomerDocumentsList />
      </>
    </TabPanel>
  );
};
