import React, { useState, useEffect, ChangeEvent } from 'react';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PasswordRulesBlock from '../signup/PasswordRulesBlock';
import { useLocation, useNavigate } from 'react-router-dom';
import { NWClient } from '../../client/NWClient';
import { Helpers } from '../../common/helpers/helpers';
import { ROUTES } from '../../common/constants/routes';
import { FORM_ITEMS } from '../../common/constants/form_items';
import FormErrorText from '../../common/form-error-text/form-error-text';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';

const PassConfirmationForm = () => {
  let passwordRepeatRef: TextValidator;
  const location = useLocation();
  const { t } = useTranslation();
  const token = new URLSearchParams(location.search).get('token');
  let email = new URLSearchParams(location.search).get('email') || '';
  email = email.replace(/ /g, '+');

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [hasLowerUpperCase, setHasLowerUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [passwordLengthEnough, setPasswordLengthEnough] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [errorTextArray, setErrorTextArray] = useState<{ [key: string]: string | object }[]>([]);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [termsOfUseAccepted, setTermsOfUseAccepted] = useState(false);
  const passwordValidated =
    passwordMatch && hasLowerUpperCase && hasNumber && hasSpecialCharacter && passwordLengthEnough;

  const canSubmit =
    password.trim() &&
    password.trim().length <= 128 &&
    passwordRepeat.trim() &&
    passwordValidated &&
    termsOfUseAccepted;
  const navigate = useNavigate();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setHasLowerUpperCase(/^(?=.*[a-z])(?=.*[A-Z])/.test(e.target.value));
    setHasNumber(/^(?=.*\d)/.test(e.target.value));
    setHasSpecialCharacter(/[!@#$%^&*№()_+\-=[\]{};':"\\|,.<>/?]+/.test(e.target.value));
    setPasswordLengthEnough(e.target.value.trim().length >= 12);
    if (passwordRepeat) {
      setPasswordMatch(passwordRepeat === e.target.value);
    }
  };
  const handleRepeatPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordRepeat(e.target.value);
    setPasswordMatch(password === e.target.value);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowPasswordRepeat = () => setShowPasswordRepeat(!showPasswordRepeat);
  const handleMouseDownPasswordRepeat = () => setShowPasswordRepeat(!showPasswordRepeat);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (
      process.env.ENV_TYPE === 'TEST' &&
      !email.trim().toLowerCase().includes('@businesskitz.com')
    ) {
      setErrorTextArray([{ errorText: t('messages.signupNotAvailable') }]);
    } else {
      setPendingStatus(true);
      const data = {
        email: email.trim().toLowerCase(),
        password,
        confirm_password: password,
        invite_token: token,
      };
      const signUpAPI = NWClient.acceptInvitation;
      signUpAPI(data)
        .then(() => {
          setPendingStatus(false);
          navigate(ROUTES.EMAIL_SENT);
        })
        .catch((error) => {
          Helpers.handleRequestError(error.response, setErrorTextArray, setPendingStatus);
        });
    }
  };
  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', () => {
      return passwordMatch;
    });
    ValidatorForm.addValidationRule('passwordLength', () => {
      return passwordLengthEnough;
    });
    ValidatorForm.addValidationRule('hasLowerUpperCase', () => {
      return hasLowerUpperCase;
    });
    ValidatorForm.addValidationRule('hasNumber', () => {
      return hasNumber;
    });
    ValidatorForm.addValidationRule('hasSpecialCharacter', () => {
      return hasSpecialCharacter;
    });
    ValidatorForm.addValidationRule('valueLength128', (value) => {
      return value.trim().length <= 128;
    });

    if (password && passwordRepeat) {
      passwordRepeatRef.validate(passwordRepeat);
    }

    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch');
      ValidatorForm.removeValidationRule('passwordLength');
      ValidatorForm.removeValidationRule('hasLowerUpperCase');
      ValidatorForm.removeValidationRule('hasNumber');
      ValidatorForm.removeValidationRule('hasSpecialCharacter');
      ValidatorForm.removeValidationRule('valueLength128');
    };
  }, [
    password,
    passwordRepeat,
    hasLowerUpperCase,
    hasNumber,
    hasSpecialCharacter,
    passwordLengthEnough,
    passwordMatch,
    passwordRepeatRef,
  ]);
  return (
    <ValidatorForm
      className='user-data-form'
      aria-invalid={Boolean(errorTextArray.length)}
      aria-errormessage={FORM_ITEMS.ERROR_CONTAINER_ID}
      onSubmit={(e: ChangeEvent<HTMLInputElement>) => handleSubmit(e)}
    >
      <TextValidator
        label={t('labels.password')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handlePasswordChange(e)}
        name='password'
        type={showPassword ? 'text' : 'password'}
        validators={[
          'required',
          'passwordLength',
          'hasLowerUpperCase',
          'hasNumber',
          'hasSpecialCharacter',
          'valueLength128',
        ]}
        errorMessages={[
          t('messages.fieldRequired'),
          t('messages.atLeast12Characters'),
          t('messages.uppercaseLowercase'),
          t('messages.atLeastOneNumber'),
          t('messages.atLeastOneSpecialCharacter'),
          t('messages.maximum128Characters'),
        ]}
        value={password}
        InputProps={{
          'aria-describedby': FORM_ITEMS.PASSWORD_RULES_BLOCK_ID,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextValidator
        label={t('labels.repeatPassword')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRepeatPasswordChange(e)}
        name='repeatPassword'
        type={showPasswordRepeat ? 'text' : 'password'}
        validators={['isPasswordMatch', 'required']}
        errorMessages={[t('messages.passwordMismatch'), t('messages.fieldRequired')]}
        value={passwordRepeat}
        ref={(r) => (passwordRepeatRef = r)}
        InputProps={{
          'aria-describedby': FORM_ITEMS.PASSWORD_RULES_BLOCK_ID,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPasswordRepeat}
                onMouseDown={handleMouseDownPasswordRepeat}
              >
                {showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormErrorText errorTextArray={errorTextArray} />
      <PasswordRulesBlock
        id={FORM_ITEMS.PASSWORD_RULES_BLOCK_ID}
        passwordLengthEnough={passwordLengthEnough}
        hasLowerUpperCase={hasLowerUpperCase}
        hasNumber={hasNumber}
        hasSpecialCharacter={hasSpecialCharacter}
        passwordMatch={passwordMatch}
      />
      <div className='checkbox-row d-flex align-items-start'>
        <Checkbox
          id='termsAndConditions'
          checked={termsOfUseAccepted}
          onChange={(event) => setTermsOfUseAccepted(event.target.checked)}
        />
        <label htmlFor='termsAndConditions'>
          {t('labels.accept')}
          <a
            href='https://businesskitz.com.au/terms-and-conditions/'
            target='_blank'
            rel='noreferrer'
          >
            {t('links.termsAndConditions')}
          </a>
          ,{' '}
          <a
            href='https://businesskitz.com.au/business-kitz-software-licence-terms-of-use/'
            target='_blank'
            rel='noreferrer'
          >
            {t('links.softwareTermsOfUse')}
          </a>
          , {t('labels.agreeWith')}
          <a href='https://businesskitz.com.au/privacy-policy/' target='_blank' rel='noreferrer'>
            {t('links.privacyPolicy')}
          </a>
          .
        </label>
      </div>
      <div className='buttons-row'>
        <Button type='submit' variant='contained' disabled={!canSubmit} size='large'>
          {t('buttons.createAccount')}
        </Button>
      </div>
      {pendingStatus ? <ProgressOverlay /> : ''}
    </ValidatorForm>
  );
};

export default PassConfirmationForm;
