import React, { useEffect, useState, useRef, ChangeEvent } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Brand from '../../../static/assets/svg/brand.svg';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import {
  fetchDocuments,
  selectAllDocuments,
  uploadDocument,
} from '../../slices/documents/documentsSlice';
import { useAppSelector } from '../../common/hooks/useAppSelector';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { Box, Chip, Paper, Stack, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { selectUser } from '../../slices/user/userSlice';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { toast } from 'react-toastify';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import { StyledTableRow } from '../category/components/TemplatesTable/components/TableRow';
import { StyledTableCell } from '../category/components/TemplatesTable/components/TableCell';
import { ActionsCell } from '../document-vault/components/ActionsCell';
import { DocumentTemplateModel } from '../../models/document.template.model';
import { NWClient } from '../../client/NWClient';
import {
  fetchDocumentContracts,
  selectAllDocumentContracts,
} from '../../slices/document-contracts/documentContractsSlice';
import { DocumentVaultItemModel } from '../../models/document.vault.item.model';
import { Helpers } from '../../common/helpers/helpers';
import { AIDocumentContractModelWithId } from '../../models/ai.document.contract.model';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { TAGS_LABELS } from '../../common/constants/tags-labels';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PopoverWithButton from '../../common/popover-with-button/PopoverWithButton';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import IconButton from '@mui/material/IconButton';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import { ROUTES } from '../../common/constants/routes';
import { Customer } from '../../models/customer.model';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';

const CustomerDocumentsList = () => {
  const token = localStorage.getItem('access_token');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const documents = useAppSelector(selectAllDocuments);
  const documentContracts = useAppSelector(selectAllDocumentContracts);
  const currentUser = useAppSelector(selectUser);
  const [documentTypeLabel, setDocumentTypeLabel] = useState([]);
  const [documentWorkflowLabel, setDocumentWorkflowLabel] = useState([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [uploadControlOpened, setUploadControlOpened] = useState<boolean>(false);
  const [templates, setTemplates] = useState<DocumentTemplateModel[]>([]);
  const [sorting, setSorting] = useState<'asc' | 'desc'>('asc');
  const [sortingBy, setSortingBy] = useState<'date' | 'name' | 'fileType'>('date');
  const customerId = useParams();

  const company = useAppSelector(selectAllCompanies)[0];
  const customers: Customer[] = company?.customers?.slice() || [];

  const documentTypeLabels = TAGS_LABELS.documentType.map((el) => t(el));
  const documentTypeLabelsSorted = documentTypeLabels
    .sort((a, b) => a.localeCompare(b))
    .map((el) => ({
      title: el,
      value: el,
    }));
  const documentWorkflowLabelsCustomer = TAGS_LABELS.documentWorkflow.customer.map((el) => t(el));
  const documentWorkflowLabelsCustomerSorted = documentWorkflowLabelsCustomer.sort((a, b) =>
    a.localeCompare(b)
  );

  const icon = <CheckBoxOutlineBlankIcon fontSize='medium' />;
  const checkedIcon = <CheckBoxIcon fontSize='medium' />;

  const getWorkflowLabels = () => {
    return documentWorkflowLabelsCustomerSorted.map((el) => ({ title: el, value: el }));
  };

  const getFileTypeFromName = (row: { name?: string; contract_generation?: number }) => {
    const lastCharacters = row.name?.slice(-4);
    if (row.contract_generation === undefined) {
      if (lastCharacters.includes('.')) {
        const charactersWithoutDot = lastCharacters.replace('.', '');
        return charactersWithoutDot;
      }
      return lastCharacters;
    } else {
      return 'bk';
    }
  };
  const documentsFiltered = documents.filter(
    (el) => el.user === currentUser.id && el.customers.includes(Number(customerId.customerId))
  );
  const documentContractsFiltered = documentContracts.filter(
    (el) => el.user === currentUser.id && el.customers.includes(Number(customerId.customerId))
  );
  const itemsComposed: (
    | DocumentTemplateModel
    | AIDocumentContractModelWithId
    | DocumentVaultItemModel
  )[] = [...documentsFiltered, ...documentContractsFiltered].map((el) => {
    return {
      ...el,
      fileType: el.fileType && el.fileType.length > 0 ? el.fileType[0] : getFileTypeFromName(el),
    };
  });

  const filtering = (el: any) => {
    const startDateCondition = new Date(el.updated) >= new Date(startDate?.toISOString());
    const endDateCondition =
      new Date(new Date(el.updated).setHours(0, 0, 0, 0)) <= new Date(endDate?.toISOString());
    const documentTypeLabelConditionDU = documentTypeLabel.every((item) =>
      el.du_types?.includes(item.value)
    );
    const documentTypeLabelConditionDC = documentTypeLabel.every((item) =>
      el.dc_types?.includes(item.value)
    );
    const documentWorkflowLabelConditionDU = documentWorkflowLabel.every((item) =>
      el.du_workflows?.includes(item.value)
    );
    const documentWorkflowLabelConditionDC = documentWorkflowLabel.every((item) =>
      el.dc_workflows?.includes(item.value)
    );

    if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length === 0 &&
      !startDate &&
      !endDate
    ) {
      if ('du_types' in el) {
        return documentTypeLabelConditionDU;
      } else if ('dc_types' in el) {
        return documentTypeLabelConditionDC;
      }
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length > 0 &&
      !startDate &&
      !endDate
    ) {
      if ('du_workflows' in el) {
        return documentWorkflowLabelConditionDU;
      } else if ('dc_workflows' in el) {
        return documentWorkflowLabelConditionDC;
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length > 0 &&
      !startDate &&
      !endDate
    ) {
      if ('du_types' in el && 'du_workflows' in el) {
        return documentTypeLabelConditionDU && documentWorkflowLabelConditionDU;
      } else if ('dc_types' in el && 'dc_workflows' in el) {
        return documentTypeLabelConditionDC && documentWorkflowLabelConditionDC;
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length === 0 &&
      startDate &&
      !endDate
    ) {
      if ('du_types' in el) {
        return documentTypeLabelConditionDU && startDateCondition;
      } else if ('dc_types' in el) {
        return documentTypeLabelConditionDC && startDateCondition;
      }
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length > 0 &&
      startDate &&
      !endDate
    ) {
      if ('du_workflows' in el) {
        return documentWorkflowLabelConditionDU && startDateCondition;
      } else if ('dc_workflows' in el) {
        return documentWorkflowLabelConditionDC && startDateCondition;
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length > 0 &&
      startDate &&
      !endDate
    ) {
      if ('du_types' in el && 'du_workflows' in el) {
        return (
          documentTypeLabelConditionDU && documentWorkflowLabelConditionDU && startDateCondition
        );
      } else if ('dc_types' in el && 'dc_workflows' in el) {
        return (
          documentTypeLabelConditionDC && documentWorkflowLabelConditionDC && startDateCondition
        );
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length === 0 &&
      !startDate &&
      endDate
    ) {
      if ('du_types' in el) {
        return documentTypeLabelConditionDU && endDateCondition;
      } else if ('dc_types' in el) {
        return documentTypeLabelConditionDC && endDateCondition;
      }
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length > 0 &&
      !startDate &&
      endDate
    ) {
      if ('du_workflows' in el) {
        return documentWorkflowLabelConditionDU && endDateCondition;
      } else if ('dc_workflows' in el) {
        return documentWorkflowLabelConditionDC && endDateCondition;
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length > 0 &&
      !startDate &&
      endDate
    ) {
      if ('du_types' in el && 'du_workflows' in el) {
        return documentTypeLabelConditionDU && documentWorkflowLabelConditionDU && endDateCondition;
      } else if ('dc_types' in el && 'dc_workflows' in el) {
        return documentTypeLabelConditionDC && documentWorkflowLabelConditionDC && endDateCondition;
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length === 0 &&
      startDate &&
      endDate
    ) {
      if ('du_types' in el) {
        return documentTypeLabelConditionDU && startDateCondition && endDateCondition;
      } else if ('dc_types' in el) {
        return documentTypeLabelConditionDC && startDateCondition && endDateCondition;
      }
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length > 0 &&
      startDate &&
      endDate
    ) {
      if ('du_workflows' in el) {
        return documentWorkflowLabelConditionDU && startDateCondition && endDateCondition;
      } else if ('dc_workflows' in el) {
        return documentWorkflowLabelConditionDC && startDateCondition && endDateCondition;
      }
    } else if (
      documentTypeLabel.length > 0 &&
      documentWorkflowLabel.length > 0 &&
      startDate &&
      endDate
    ) {
      if ('du_types' in el && 'du_workflows' in el) {
        return (
          documentTypeLabelConditionDU &&
          documentWorkflowLabelConditionDU &&
          startDateCondition &&
          endDateCondition
        );
      } else if ('dc_types' in el && 'dc_workflows' in el) {
        return (
          documentTypeLabelConditionDC &&
          documentWorkflowLabelConditionDC &&
          startDateCondition &&
          endDateCondition
        );
      }
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length === 0 &&
      startDate &&
      !endDate
    ) {
      return startDateCondition;
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length === 0 &&
      !startDate &&
      endDate
    ) {
      return endDateCondition;
    } else if (
      documentTypeLabel.length === 0 &&
      documentWorkflowLabel.length === 0 &&
      startDate &&
      endDate
    ) {
      return startDateCondition && endDateCondition;
    }
  };
  const sortByDate = (a: any, b: any) => {
    return (
      Number(new Date((sorting === 'asc' ? b : a).updated)) -
      Number(new Date((sorting === 'asc' ? a : b).updated))
    );
  };
  const sortByName = (a: any, b: any) => {
    return (sorting === 'asc' ? a : b).name.localeCompare((sorting === 'asc' ? b : a).name);
  };
  const sortByFileType = (a: any, b: any) => {
    return (sorting === 'asc' ? a : b).fileType.localeCompare((sorting === 'asc' ? b : a).fileType);
  };
  const sortingCondition =
    documentTypeLabel.length > 0 || documentWorkflowLabel.length > 0 || startDate || endDate;
  const itemsSortedByDate = sortingCondition
    ? [...itemsComposed].filter((el) => filtering(el)).sort((a, b) => sortByDate(a, b))
    : [...itemsComposed].sort((a, b) => sortByDate(a, b));
  const itemsSortedByName = sortingCondition
    ? [...itemsComposed].filter((el) => filtering(el)).sort((a, b) => sortByName(a, b))
    : [...itemsComposed].sort((a, b) => sortByName(a, b));
  const itemsSortedByFileType = sortingCondition
    ? [...itemsComposed].filter((el) => filtering(el)).sort((a, b) => sortByFileType(a, b))
    : [...itemsComposed].sort((a, b) => sortByFileType(a, b));

  const handleSorting = (sortingValue: 'name' | 'fileType' | 'date') => {
    if (sortingValue !== sortingBy) {
      setSortingBy(sortingValue);
      setSorting('asc');
    } else {
      if (sorting === 'asc') {
        setSorting('desc');
      } else {
        setSorting('asc');
      }
    }
  };

  const getItemsArray = () => {
    switch (sortingBy) {
      case 'name':
        return itemsSortedByName;
      case 'date':
        return itemsSortedByDate;
      case 'fileType':
        return itemsSortedByFileType;
    }
  };
  const handleNavigateByRowClick = (row: any) => {
    navigate(
      `${ROUTES.DOCUMENT_VAULT}/customers/${row.id}${
        row.contract_generation ? '?initialTab=1&contractGenerated=true' : ''
      }`
    );
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchDocuments({
        token,
        data: { user: currentUser.id, customers: Number(customerId.customerId) },
      })
    )
      .unwrap()
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      });
    dispatch(
      fetchDocumentContracts({
        token,
        data: { user: currentUser.id, customers: Number(customerId.customerId) },
      })
    )
      .unwrap()
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <div className='d-flex align-items-start header mt-4 mb-4'>
        <DescriptionIcon sx={{ fontSize: '2rem', marginRight: '0.75rem', marginLeft: '1.5rem' }} />
        <h2 className='text-capitalize text-center '>{t('labels.documents')}</h2>
        <Button
          variant='contained'
          sx={{ marginLeft: 'auto', marginRight: '1.5rem' }}
          onClick={() => navigate(`${ROUTES.DOCUMENT_VAULT}/customers`)}
        >
          {t('buttons.upload')}
        </Button>
      </div>
      {loading ? (
        <div className='text-center'>
          <ProgressOverlay className='position-relative' />
        </div>
      ) : (
        <>
          <TableContainer sx={{ overflow: 'hidden', borderRadius: 5, px: 0.25 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <TableSortLabel
                      active={sortingBy === 'name'}
                      direction={sortingBy === 'name' ? sorting : 'asc'}
                      onClick={() => handleSorting('name')}
                      sx={{ marginLeft: '1rem' }}
                    >
                      {t('labels.name')}
                      {sortingBy === 'name' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {sorting === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                    <TableSortLabel
                      active={sortingBy === 'fileType'}
                      direction={sortingBy === 'fileType' ? sorting : 'asc'}
                      onClick={() => handleSorting('fileType')}
                      sx={{ marginRight: '6rem' }}
                    >
                      {t('labels.fileType')}
                      {sortingBy === 'fileType' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {sorting === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    <TableSortLabel
                      active={sortingBy === 'date'}
                      direction={sortingBy === 'date' ? sorting : 'asc'}
                      onClick={() => handleSorting('date')}
                      sx={{ marginRight: '6rem' }}
                    >
                      {t('labels.date')}
                      {sortingBy === 'date' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {sorting === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align='center'>{t('labels.labels')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ zIndex: 5 }}>
                {getItemsArray().map((row) => (
                  <StyledTableRow
                    sx={{
                      height: '4rem',
                    }}
                    key={row.id}
                  >
                    <StyledTableCell onClick={() => handleNavigateByRowClick(row)}>
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell onClick={() => handleNavigateByRowClick(row)}>
                      {row.fileType === 'bk' ? (
                        <Tooltip title={t('labels.bkGeneratedDocument')} placement='top' arrow>
                          <div className={'file-type-label ' + row.fileType}>
                            <Brand />
                          </div>
                        </Tooltip>
                      ) : (
                        <div className={'file-type-label ' + row.fileType}>{row.fileType}</div>
                      )}
                    </StyledTableCell>
                    <StyledTableCell onClick={() => handleNavigateByRowClick(row)}>
                      {Helpers.timeStampToDateV2(row.updated)}
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      {('du_types' in row && row.du_types.length > 0) ||
                      ('du_workflows' in row && row.du_workflows.length > 0) ||
                      ('customers' in row && row.customers.length > 0) ||
                      ('dc_types' in row && row.dc_types.length > 0) ||
                      ('dc_workflows' in row && row.dc_workflows.length > 0) ? (
                        <PopoverWithButton
                          id={String(row.id)}
                          buttonText={String(
                            ('du_types' in row ? row.du_types.length : 0) +
                              ('du_workflows' in row ? row.du_workflows.length : 0) +
                              ('dc_types' in row ? row.dc_types.length : 0) +
                              ('dc_workflows' in row ? row.dc_workflows.length : 0) +
                              ('customers' in row ? row.customers.length : 0)
                          )}
                        >
                          <Stack
                            direction='row'
                            sx={{
                              alignItems: 'center',
                              flexWrap: 'wrap',
                              gap: '0.375rem',
                              maxWidth: '200px',
                              padding: '0.5rem',
                            }}
                          >
                            {'du_types' in row
                              ? row.du_types.map((el) => <Chip key={el} label={el} />)
                              : null}
                            {'du_workflows' in row
                              ? row.du_workflows.map((el) => <Chip key={el} label={el} />)
                              : null}
                            {'dc_types' in row
                              ? row.dc_types.map((el) => <Chip key={el} label={el} />)
                              : null}
                            {'dc_workflows' in row
                              ? row.dc_workflows.map((el) => <Chip key={el} label={el} />)
                              : null}
                            {row.customers.map((customerId) => {
                              const customer = customers.find(
                                (cus) => cus.id === Number(customerId)
                              );
                              return customer ? (
                                <Chip key={customerId} label={customer.customer_name} />
                              ) : null;
                            })}
                          </Stack>
                        </PopoverWithButton>
                      ) : null}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default CustomerDocumentsList;
