import React from 'react';
import FeaturesCarousel from '../../common/features-slider/FeaturesCarousel';
import { useTranslation } from 'react-i18next';

const FeaturesContentLogIn = () => {
  const { t } = useTranslation();
  const itemsArray = [
    {
      slideTitle: t('slideTitles.documentLibrary'),
      descriptionText: t('slidesDescription.premiumDocs'),
    },
    {
      slideTitle: t('slideTitles.digitalSigning'),
      descriptionText: t('slidesDescription.seamlesslySign'),
    },
    {
      slideTitle: t('slideTitles.documentVault'),
      descriptionText: t('slidesDescription.documentStorage'),
    },
    {
      slideTitle: t('slideTitles.ai'),
      descriptionText: t('slidesDescription.aiBrief'),
    },
  ];
  return <FeaturesCarousel itemsArray={itemsArray} />;
};

export default FeaturesContentLogIn;
