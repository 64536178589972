import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { selectAllCompanies } from '../../slices/companies/companiesSlice';
import { useAppSelector } from '../hooks/useAppSelector';
import { Supplier } from '../../models/supplier.model';
import { Customer } from '../../models/customer.model';
import { DocumentVaultItemModel } from '../../models/document.vault.item.model';
import { selectAllDocuments } from '../../slices/documents/documentsSlice';
import { selectAllDocumentContracts } from '../../slices/document-contracts/documentContractsSlice';
import { AIDocumentContractModel } from '../../models/ai.document.contract.model';
import { RootState } from '../../store';
import { selectEmployeeById } from '../../slices/employees/employeesSlice';
import { ROUTES } from '../constants/routes';

export const NwBreadCrumbs = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userId } = useParams();
  const { supplierId } = useParams();
  const { customerId } = useParams();
  const { documentId } = useParams();
  const { name } = useParams();
  const b = useParams();
  const pathnames = decodeURI(pathname)
    .split('/')
    .filter((x) => x);
  const [params] = useSearchParams();
  const contractGenerated = params.get('contractGenerated');
  const company = useAppSelector(selectAllCompanies)[0];
  const documents = useAppSelector(selectAllDocuments);
  const documentContracts = useAppSelector(selectAllDocumentContracts);
  const employee = useAppSelector((state: RootState) => selectEmployeeById(state, Number(userId)));
  const user = userId ? company?.members.find((el) => el.id === Number(userId)) : null;
  const userName = user ? user.first_name + ' ' + user.last_name : null;
  const employeeName = employee ? employee.first_name + ' ' + employee.last_name : null;
  const supplier: Supplier = supplierId
    ? company?.suppliers.find((el) => el.id === Number(supplierId))
    : null;
  const supplierName = supplier ? supplier.name : null;
  const customer: Customer = customerId
    ? company?.customers.find((el) => el.id === Number(customerId))
    : null;
  const customerName = customer ? customer.customer_name : null;
  const document: DocumentVaultItemModel | AIDocumentContractModel =
    documentId && contractGenerated !== 'true'
      ? documents.find((el) => el.id === Number(documentId))
      : documentContracts.find((el) => el.id === Number(documentId));
  const documentName = document ? document.name : documentId;
  const nameBreadcrumb = () => {
    if (userId && !pathname.includes(ROUTES.EMPLOYEE_PROFILES)) {
      return userName;
    } else if (supplierId) {
      return supplierName;
    } else if (customerId) {
      return customerName;
    } else if (documentId) {
      return documentName;
    } else if (pathname.includes(ROUTES.EMPLOYEE_PROFILES) && userId) {
      return employeeName;
    } else if (name === 'uk' || name === 'au') {
      return name.toUpperCase();
    } else if (pathname.includes(ROUTES.PRICING)) {
      return t('pages.plan');
    }
  };
  return (
    <Breadcrumbs className='breadcrumbs' separator={<ChevronRightIcon />} aria-label='breadcrumb'>
      {pathnames.length > 0 ? (
        <Link onClick={() => navigate('/')}>{t('breadcrumbs.home')}</Link>
      ) : (
        <h3 className='nw-app-bar-toolbar-title'>{t('breadcrumbs.home')}</h3>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography aria-current='page' key={name}>
            {name === userId ||
            name === customerId ||
            name === supplierId ||
            name === documentId ||
            name === 'uk' ||
            name === 'au' ||
            pathname.includes(ROUTES.PRICING)
              ? nameBreadcrumb()
              : name.replaceAll('-', ' ')}
          </Typography>
        ) : (
          <Link key={name} onClick={() => navigate(routeTo)}>
            {name === 'uk' || name === 'au' ? nameBreadcrumb() : name.replaceAll('-', ' ')}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};
